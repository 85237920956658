import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';
import { Observable } from 'rxjs';
import { Mail } from '../model/mail';

@Injectable({
    providedIn: 'root'
  })
  export class MailService extends AbstractService {

    constructor(public http: HttpClient) {
      super(http);
    }
  
   
    enviarMail(emailData: Mail, file:any): Observable<any> {
        const formData = new FormData();
        formData.append('emailData', JSON.stringify(emailData));
        formData.append('file', file);

        return this.postWithObserve(this.EP_MAIL + '/send', formData);
    }       
  

}