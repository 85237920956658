import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import { Cotizacion } from "src/app/model/cotizacion";
import { ItemCotizacion } from "src/app/model/item_cotizacion";
import * as fontUtils from "./reportePicksFontLoader";

const MONEDA_USA = 'USD ';
const MONEDA_AR = 'AR$ ';


//Si se quiere obtener el file para mandarlo por mail enviar descargar = false;
export function generarPDFCotizacion(cotizacion: Cotizacion, precioDolar: number, datos : any, descargar: boolean, nombreArchivo: string) {

  //nombreArchivo = 'pepe.pdf';
  let headerEnd = 0;
  let subHeaderEnd = 0;
  let filaInicioBody = 0;
  let footerStart = 0;

  //Crear nuevo PDF
  var doc = new jsPDF({
    orientation: "portrait",
    unit: "px"
  });

  fontUtils.cargarFuentes(doc);

  armarHeader(doc, cotizacion);
  
  //dibujarGrilla(doc);
  footerStart = armarBody(doc, cotizacion, precioDolar, datos);
    
  armarFooter(doc, footerStart );
 

  if (descargar) {
    //Si se quiere descargar usar esto
    doc.save(nombreArchivo);
  } else {
    return doc.output('blob');
  }

}

function armarBody(doc, cotizacion : Cotizacion, precioDolar: number, datos:any ) {

  let lineaActual = 0;

  lineaActual = mostrarCuadroProductos(doc, cotizacion);
  lineaActual = mostrarDetalleDeGastos(doc, lineaActual, precioDolar, datos);
  lineaActual = mostrarGastosDeEnvio(doc, lineaActual, precioDolar,  datos);
  lineaActual = mostarTotal(doc, lineaActual, precioDolar, datos);

  return lineaActual;
}

function mostrarCuadroProductos(doc, cotizacion: Cotizacion ){
  let titulo = 'Listado de Productos';

  let col1 = 'Link';
  let col2 = 'Categoria';
  let col3 = 'Precio';
  let col4 = 'Shipping';
  let col5 = 'Peso';
  let col6 = 'Medidas';

  const TAMANIO_LONG_LINK = 120;
  const TAMANIO_LONG_CAT = 60;
  const TAMANIO_RENGLON = 13;

  let cursorYPos = 85;
  //Titulo
  doc.setFillColor('#c7c5d4'); //gris
  doc.roundedRect(20, cursorYPos-15, 410, 20, 5, 5, 'F');
  doc.setTextColor('#003399'); //azul letra
  doc.setFont('cerealbold');
  doc.setFontSize(12);
  doc.text(titulo, 180, cursorYPos-2);
  

  //Header Tabla
  doc.setFontSize(10);
  doc.setFont('cerealmedium');
  doc.setTextColor(0,0,0);
  cursorYPos += 15;

  doc.text(col1, 80, cursorYPos);
  doc.text(col2, 160, cursorYPos);
  doc.text(col3, 230, cursorYPos);
  doc.text(col4, 270, cursorYPos);
  doc.text(col5, 315, cursorYPos);
  doc.text(col6, 380, cursorYPos);

  //Productos
  let items = cotizacion.items;
  doc.setFontSize(10);
  doc.setFont('cereal');
  doc.setTextColor(0,0,0);
  cursorYPos += 15;

  for(let i = 0; i < items.length; i++){     
    let linkCompleto = items[i].link;
    let categoria = obtenerStringDeLongitud(items[i].categoria.descripcion, 50, doc)
    let precio = 'USD ' + items[i].importeEnDeposito.toFixed(2).toString();
    let shipping = 'USD ' + items[i].shipping.toFixed(2).toString();
    let peso = items[i].peso.toFixed(2).toString();
    let largo = items[i].largo.toFixed(2).toString();
    let ancho = items[i].ancho.toFixed(2).toString();
    let alto = items[i].alto.toFixed(2).toString();
    let medidas = `${largo} x ${ancho} x ${alto}`;
    
    let linkCorto = obtenerStringDeLongitud(linkCompleto, TAMANIO_LONG_LINK, doc);
    
    
    //link
    doc.setTextColor(0, 75, 165);
    doc.textWithLink(linkCorto, 30, cursorYPos, { url: linkCompleto });
    //linea de hipervinculo
    doc.setDrawColor(0, 75, 165);
    doc.setLineWidth(0.5);
    doc.line(30, cursorYPos + 1, 30 + Math.round(doc.getTextWidth(linkCorto)), cursorYPos + 1);
    doc.setTextColor(0, 0, 0);
    doc.setDrawColor(0, 0, 0);


    doc.text(categoria, 160, cursorYPos);

    let posXPrecio = alinearADerecha(260, precio, doc);
    doc.text(precio, posXPrecio, cursorYPos);

    let posXShipping = alinearADerecha(305, shipping, doc);
    doc.text(shipping, posXShipping, cursorYPos);

    let posXPeso = alinearADerecha(335, peso, doc);
    doc.text(peso, posXPeso, cursorYPos);
    
    let posXMedidas = alinearADerecha(425, medidas, doc);
    doc.text(medidas, posXMedidas, cursorYPos);

    cursorYPos += TAMANIO_RENGLON;

  }

  return cursorYPos;


}


function mostrarDetalleDeGastos(doc, cursorYPos: number, precioDolar: number, datos: any){
  let titulo = 'Si Waiver compra por vos  ->  Detalle de gastos compra en USA';
  
  let text1 = 'Productos';
  let text2 = 'Shipping en USA';
  let text3 = 'Coordination Fee';
  let text4 = 'Taxes USA';
  let text5 = 'Gastos de Gestión en USA';
  let text6 = 'Gastos Administrativos en USA';
  let text7 = 'Honorarios en Argentina';
  let text8 = 'IVA Local';

  let text9 = 'TOTAL EN USA';
  let text10 = 'En pesos al cambio de hoy $ ' + precioDolar.toFixed(2).toString();
 
  let text1Value = MONEDA_USA + datos.totalImporteProductos;
  let text2Value = MONEDA_USA + datos.totalShipping;
  let text3Value = MONEDA_USA + datos.totalCoordinationFee;
  let text4Value = MONEDA_USA + datos.totalTaxes;
  let text5Value = MONEDA_USA + datos.totalGastosGestionUSA;
  let text6Value = MONEDA_USA + datos.totalGastosAdminUsa;
  let text7Value = MONEDA_USA + datos.totalHonorariosArgentina;
  let text8Value = MONEDA_USA + datos.totalIvaUSA;
  let text9Value = MONEDA_USA + datos.totalEnUSA;
  let text10Value = MONEDA_AR + datos.totalEnUSAConvertido;


  const INICIO_X_COL_1 = 40;
  const FIN_X_COL_2 = 410;
  const TAMANIO_RENGLON = 15;

  cursorYPos += 0;
  //Titulo
  doc.setFillColor('#c7c5d4');
  doc.roundedRect(20, cursorYPos, 410, 20, 5, 5, 'F');
  doc.setTextColor('#003399');
  doc.setFont('cerealbold');
  doc.setFontSize(12);
  cursorYPos += 15;
  doc.text(titulo, 80, cursorYPos-2);

  //Contenido
  //doc.setTextColor('#003399');
  doc.setTextColor(0,0,0);
  doc.setFont('cerealmedium');
  doc.setFontSize(11);

  let posXText1 = alinearADerecha(FIN_X_COL_2, text1Value, doc);
  let posXText2 = alinearADerecha(FIN_X_COL_2, text2Value, doc);
  let posXText3 = alinearADerecha(FIN_X_COL_2, text3Value, doc);
  let posXText4 = alinearADerecha(FIN_X_COL_2, text4Value, doc);
  let posXText5 = alinearADerecha(FIN_X_COL_2, text5Value, doc);
  let posXText6 = alinearADerecha(FIN_X_COL_2, text6Value, doc);
  let posXText7 = alinearADerecha(FIN_X_COL_2, text7Value, doc);
  let posXText8 = alinearADerecha(FIN_X_COL_2, text8Value, doc);
  let posXText9 = alinearADerecha(FIN_X_COL_2, text9Value, doc);
  let posXText10 = alinearADerecha(FIN_X_COL_2, text10Value, doc);
  

  cursorYPos += 17;
  doc.text(text1, INICIO_X_COL_1, cursorYPos);
  doc.text(text1Value, posXText1, cursorYPos);

  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  if(datos.totalShipping){
    cursorYPos += TAMANIO_RENGLON;
    doc.text(text2, INICIO_X_COL_1, cursorYPos);
    doc.text(text2Value, posXText2, cursorYPos);
    dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);
  }

  if(datos.totalCoordinationFee){
    cursorYPos += TAMANIO_RENGLON;
    doc.text(text3, INICIO_X_COL_1, cursorYPos);
    doc.text(text3Value, posXText3, cursorYPos);
    dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);
  }

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text4, INICIO_X_COL_1, cursorYPos);
  doc.text(text4Value, posXText4, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text5, INICIO_X_COL_1, cursorYPos);
  doc.text(text5Value, posXText5, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text6, INICIO_X_COL_1, cursorYPos);
  doc.text(text6Value, posXText6, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text7, INICIO_X_COL_1, cursorYPos);
  doc.text(text7Value, posXText7, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text8, INICIO_X_COL_1, cursorYPos);
  doc.text(text8Value, posXText8, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#003399', 1.5);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text9, INICIO_X_COL_1, cursorYPos);
  doc.text(text9Value, posXText9, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#003399', 1.5);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text10, INICIO_X_COL_1, cursorYPos);
  doc.text(text10Value, posXText10, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 1);
 

  return cursorYPos;
}


function mostrarGastosDeEnvio(doc, cursorYPos: number, precioDolar: number, datos: any){
  let titulo = 'Envío e impuestos de Aduana';
  
  let text1 = 'Flete Internacional';
  let text2 = 'Excedente Kilo Volumétrico';
  let text3 = 'Impuestos de Aduana';
  let text4 = 'Tarifa de Procesamiento';
  let text4b = 'Almacenaje TCA';
  let text5 = 'Entrega en ' + datos.localidaEntrega;
  let text6 = 'Iva Local';
  let text7 = 'Descuento Gold + 10 Kg';
  let text8 = 'Descuento Premium + 25Kg';
  let text9 = 'TOTAL ENVIO E IMPUESTOS DE ADUANA';
  let text10 = 'En pesos al cambio de hoy $ ' + precioDolar.toFixed(2).toString();

  let text1Value = MONEDA_USA + datos.totalFleteInternacional;
  let text2Value = MONEDA_USA + datos.totalExcedentoKiloVolumetrico;
  let text3Value = MONEDA_USA + datos.totalImpuestosAduana;
  let text4Value = MONEDA_USA + datos.totalTarifaProcesamiento;
  let text4bValue = MONEDA_USA + datos.totalTCA;
  let text5Value = datos.valorEntrega; //ya vienen con la moneda
  let text6Value = MONEDA_USA + datos.totalIvaLocal;
  let text7Value = MONEDA_USA + datos.descuentoGold; 
  let text8Value = MONEDA_USA + datos.descuentoPremium; 
  let text9Value = MONEDA_USA + datos.totalEnvioImpuestosAduana;
  let text10Value = MONEDA_AR + datos.totalEnvioImpuestosAduanaConvertido;

  const INICIO_X_COL_1 = 40;
  const FIN_X_COL_2 = 410;
  const TAMANIO_RENGLON = 15;
  cursorYPos += 15; 

  //Titulo
  doc.setFillColor('#c7c5d4'); //e4e6eb
  doc.roundedRect(20, cursorYPos, 410, 20, 5, 5, 'F');
  doc.setTextColor('#003399');
  doc.setFont('cerealbold');
  doc.setFontSize(12);
  cursorYPos += 10;
  doc.text(titulo, 165, cursorYPos+2);

  
  //Contenido
  //doc.setTextColor('#003399');
  doc.setTextColor(0,0,0);
  doc.setFont('cerealmedium');
  doc.setFontSize(11);

  let posXText1 = alinearADerecha(FIN_X_COL_2, text1Value, doc);
  let posXText2 = alinearADerecha(FIN_X_COL_2, text2Value, doc);
  let posXText3 = alinearADerecha(FIN_X_COL_2, text3Value, doc);
  let posXText4 = alinearADerecha(FIN_X_COL_2, text4Value, doc);
  let posXText5 = alinearADerecha(FIN_X_COL_2, text5Value, doc);
  let posXText6 = alinearADerecha(FIN_X_COL_2, text6Value, doc);
  let posXText7 = alinearADerecha(FIN_X_COL_2, text7Value, doc);
  let posXText8 = alinearADerecha(FIN_X_COL_2, text8Value, doc);
  let posXText9 = alinearADerecha(FIN_X_COL_2, text9Value, doc);
  let posXText10 = alinearADerecha(FIN_X_COL_2, text10Value, doc);


  cursorYPos += 23;
  doc.text(text1, INICIO_X_COL_1, cursorYPos);
  doc.text(text1Value, posXText1, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text2, INICIO_X_COL_1, cursorYPos);
  doc.text(text2Value, posXText2, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text3, INICIO_X_COL_1, cursorYPos);
  doc.text(text3Value, posXText3, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text4, INICIO_X_COL_1, cursorYPos);
  doc.text(text4Value, posXText4, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text4b, INICIO_X_COL_1, cursorYPos);
  doc.text(text4bValue, posXText4, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text5, INICIO_X_COL_1, cursorYPos);
  doc.text(text5Value, posXText5, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text6, INICIO_X_COL_1, cursorYPos);
  doc.text(text6Value, posXText6, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 0.2);

  if(datos.mostrarDescuentoGold){
    cursorYPos += TAMANIO_RENGLON;
    doc.text(text7, INICIO_X_COL_1, cursorYPos);
    doc.text(text7Value, posXText7, cursorYPos);
    dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#003399', 1.5);
  }

  if(datos.mostrarDescuentoPremium){
    cursorYPos += TAMANIO_RENGLON;
    doc.text(text8, INICIO_X_COL_1, cursorYPos);
    doc.text(text8Value, posXText8, cursorYPos);
    dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#003399', 1.5);
  }

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text9, INICIO_X_COL_1, cursorYPos);
  doc.text(text9Value, posXText9, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#003399', 1.5);

  cursorYPos += TAMANIO_RENGLON;
  doc.text(text10, INICIO_X_COL_1, cursorYPos);
  doc.text(text10Value, posXText10, cursorYPos);
  dibujarLinea(doc, INICIO_X_COL_1, FIN_X_COL_2, cursorYPos+4, '#c7c5d4', 1);


  return cursorYPos;

}

function mostarTotal(doc, cursorYPos: number, precioDolar: number,  datos: any){
  
  
  let texto = `En total vas a pagar a dolar oficial ${MONEDA_AR}${precioDolar.toFixed(2)}`;
  let totalEnDolares = MONEDA_USA + formatMoney(datos.grandTotal);
  let totalEnPesos = MONEDA_AR + formatMoney(datos.grandTotalConvertido);


  doc.setTextColor('#4c8dff');
  doc.setFont('cerealbold');
  doc.setFontSize(16);

  cursorYPos += 35;
  doc.text(texto, 90, cursorYPos);
  
  cursorYPos += 18;
  doc.text(totalEnDolares, 180, cursorYPos);
  
  cursorYPos += 18;
  doc.text(totalEnPesos, 175, cursorYPos);

  return cursorYPos;
}

export function armarHeader(doc: any, cotizacion: Cotizacion) {

  let pathLogo = "/assets/reporte/bannerWaiverPicks.png";
  let logoExtension = 'PNG';
  let titulo = `Cotización Nro.  ${cotizacion.id}` ;

  //Logo
  doc.addImage(pathLogo, logoExtension, 20, 20, 410, 46);

  doc.setFontSize(14);
  doc.setTextColor(255, 255, 255);
  doc.setFont('cerealbold');

  doc.text(titulo, 170, 45);
  
}

function dibujarGrilla(doc) {

  doc.setFontSize(11);
  doc.setFont('cereal');
  doc.setLineWidth(0.5);
  doc.setTextColor(10, 11, 12);
  //verticales
  let separacionHorizontal = 20;
  let alturaLinea = 620;
  let anchoHoja = 450

  for (let i = separacionHorizontal; i < anchoHoja; i = i + separacionHorizontal) {
    doc.line(i, 0, i, alturaLinea); //x,y  x,y
    doc.text(i + '', i, 9); //text, x, y
  }

  //horizontales
  let separacionVertical = 10;
  let longitudLinea = 440;
  let altoHoja = 750
  for (let i = separacionVertical; i < altoHoja; i = i + separacionVertical) {
    doc.line(0, i, longitudLinea, i); //x,y  x,y
    doc.text(i + '', 10, i); //text, x, y
  }

  doc.setTextColor(0, 0, 0);



}

function armarFooter(doc, cursorYPos: number ) {

  let titulo = 'Su pago';
  let extensionJPG = 'JPG';
  let extensionPNG = 'PNG';

  //let imageMedioDePago = "/assets/reporte/medio-de-pago-logo.png";
  let imagenTransferencia = "/assets/reporte/transferencia-logo.png";
  let imagenMercadoPago = "/assets/reporte/mercadopago.jpg";
  //let imagenCabal = "/assets/reporte/cabal-logo.png";
  let imgenVisaMasterAmex = "/assets/reporte/visa-mastercard-american-express-logo-mjlW.png";
  //let imagenPayPal = "/assets/reporte/Paypal_2014_logo.png";
  let imagenFiserv = "/assets/reporte/fiservLogo.png";
  let imgenPagoMisCuentas = "/assets/reporte/pagomiscuentasLogo.png";

  cursorYPos += 40; 

  //Logo
  //doc.addImage(imageMedioDePago, extensionPNG, 150, 550, 150, 26);
  //doc.addImage(imagenCabal, extensionPNG, 178, 585, 26, 15);
  //doc.addImage(imagenPayPal, extensionPNG, 210, 585, 17, 19);
  doc.addImage(imgenVisaMasterAmex, extensionPNG, 40, cursorYPos, 75, 17);
  doc.addImage(imagenMercadoPago, extensionJPG, 125, cursorYPos-8, 64, 32);
  doc.addImage(imagenTransferencia, extensionPNG, 195, cursorYPos, 56, 25);
  doc.addImage(imgenPagoMisCuentas, extensionPNG, 255, cursorYPos, 96, 21);
  doc.addImage(imagenFiserv, extensionPNG, 360, cursorYPos-10, 60, 37);

}


////////////////////HELPERS///////////////////

function obtenerValorDelDescuento(cotizacion: Cotizacion): any {
  let descuento = 0;
  if (cotizacion.descuentoGold && cotizacion.descuentoGold != 0) {
    descuento = cotizacion.descuentoGold;
  } else if (cotizacion.descuentoPremium && cotizacion.descuentoPremium != 0) {
    descuento = cotizacion.descuentoPremium;
  }

  return descuento;

}

function isDescuentoGold(cotizacion: Cotizacion): Boolean {
  let isDescuentoGold = false;
  if (cotizacion.descuentoGold && cotizacion.descuentoGold != 0) {
    isDescuentoGold = true;
  }
  return isDescuentoGold;
}

function isDescuentoPremium(cotizacion: Cotizacion): Boolean {
  let isDescuentoPremium = false;
  if (cotizacion.descuentoPremium && cotizacion.descuentoPremium != 0) {
    isDescuentoPremium = true;
  }
  return isDescuentoPremium;
}


function calcularTotalCompra(cotizacion: Cotizacion) {

  let total = 0;

  for (let i = 0; i < cotizacion.items.length; i++) {
    let item: ItemCotizacion = cotizacion.items[i];
    let producto = item.importeEnDeposito;
    let shipping = item.shipping;
    let taxes = item.taxes;
    let gastosGestion = item.gastosGestionUSA;
    let gastosAdm = item.gastosAdminUsa;
    let honorariosArgentina = item.honorariosArgentina;
    let ivaLocal = item.ivaLocalUSA;

    let subtotal = producto + shipping + taxes + gastosGestion + gastosAdm + honorariosArgentina + ivaLocal;

    total = total + subtotal;
  }

  return total;
}

function calcularTotalEnvioImpuestos(cotizacion: Cotizacion) {

  let total = 0;
  let entrega = cotizacion.entrega ? cotizacion.entrega : 0;
  let descuento = obtenerValorDelDescuento(cotizacion);

  for (let i = 0; i < cotizacion.items.length; i++) {
    let item: ItemCotizacion = cotizacion.items[i];
    let fleteInt = item.fleteInternacional;
    let excedenteKiloVol = item.excedenteKiloVolumetrico;
    let impAduana = item.impuestosAduana;
    let tarifaProcesamiento = item.tarifaProcesamiento;
    let ivaLocal = item.ivaLocal;

    let subtotal = fleteInt + excedenteKiloVol + impAduana + tarifaProcesamiento + ivaLocal;

    total = total + subtotal;

  }

  total += cotizacion.tca;

  // el iva local incluye la tarifa de procesamiento + la entrega local
  total += cotizacion.entrega * cotizacion.alicuotaIva;
  total += entrega;
  total += cotizacion.res3244;

  //el valor del descuento se guarda con signo negativo
  total = total + descuento;

  return total;

}


function getFechaFormateada(fecha: Date, separador: String) {
  let dia = ("0" + fecha.getDate()).slice(-2); //Devuelve el dia con 2 digitos
  let mes = ("0" + (fecha.getMonth() + 1)).slice(-2); //Devuelve el mes con 2 digitos
  let anio = fecha.getFullYear();

  return dia + separador + mes + separador + anio;
}


function alinearADerecha(posXDerecha: number, texto: string, doc) {
  let posicionX = 0;
  let anchoTexto = doc.getTextWidth(texto);

  posicionX = posXDerecha - anchoTexto;
  posicionX = Math.round(posicionX);

  return posicionX;
}

function formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - Number(i)).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}

function obtenerStringDeLongitud(cadena: string, longitudFinal: number, doc) {
  let largoActual = Math.round(doc.getTextWidth(cadena));

  while (largoActual > longitudFinal) {
    cadena = cadena.slice(0, -1);
    largoActual = Math.round(doc.getTextWidth(cadena));
  }

  return cadena;

}

function dibujarLinea(doc, xInicio , xFin, y, color : string, width: number){

  doc.setDrawColor(color);
  doc.setLineWidth(width);
  doc.line(xInicio, y , xFin, y);

}



