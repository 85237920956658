import { Categoria } from "./categoria";

export class ItemCotizacion {

    id: number;
    link: string;
    descripcion: string;
    categoria: Categoria;
    peso: number;
    alto: number;
    ancho: number;
    largo: number;
    importeEnDeposito: number;
    shipping: number;
    coordinationFee: number;
    taxes: number;
    gastosGestionUSA: number;
    gastosAdminUsa: number;
    honorariosArgentina: number;
    ivaLocalUSA: number;
    fleteInternacional: number;
    excedenteKiloVolumetrico: number;
    impuestosAduana: number;
    tarifaProcesamiento: number;
    ivaLocal: number;
    fob: number;
    freight: number;
    insurance: number;
    cif: number;
    derechosImportacion: number;
    tasaEstadistica: number;
    ivaAduana: number;
    impuestosInternos: number;
    tca: number;
    handlingUSA: number;
	handlingARG: number;

    constructor() {
        this.id = null;
        this.link = null;
        this.descripcion = null;
        this.categoria = null;
        this.peso = null;
        this.alto = 1;
        this.ancho = 1;
        this.largo = 1;
        this.importeEnDeposito = null;
        this.shipping = null;
        this.coordinationFee = 0;
        this.taxes = 0;
        this.gastosGestionUSA = 0;
        this.gastosAdminUsa = 0;
        this.honorariosArgentina = 0;
        this.ivaLocalUSA = 0;
        this.fleteInternacional = 0;
        this.excedenteKiloVolumetrico = 0;
        this.impuestosAduana = 0;
        this.tarifaProcesamiento = 0;
        this.ivaLocal = 0;
        this.fob = 0;
        this.freight = 0;
        this.insurance = 0;
        this.cif = 0;
        this.derechosImportacion = 0;
        this.tasaEstadistica = 0;
        this.ivaAduana = 0;
        this.impuestosInternos = 0;
        this.tca = 0;
        this.handlingARG = 0;
        this.handlingUSA = 0;

    }
}