import { Entrega } from "./entrega";
import { ItemCotizacion } from "./item_cotizacion";

export class Cotizacion {
    id: number;
    localidadEntrega: Entrega;
    entrega: number;
    descuentoGold: number;
    descuentoPremium: number;
    items: ItemCotizacion[];
    email: string;
    cuit: number;
    celular: string;
    vendedorNombre: string;
    idVendedor: number;
    fechaCreacion: Date;
    fechaUltimaActualizacion: Date;
    iva: number;
    direccion: string;
    localidad: string;
    provincia: string;
    codigoPostal: string;
    alicuotaIva: number;
    codigoDescuento: string;
    tipoCambioGastosExterior: number;
    tipoCambioGastosArg: number;
    res3244: number;
    tca: number;
    gestionCompra: boolean;
    gestionEnvio: boolean;
    handlingUSA: number;
	handlingARG: number;

    constructor() {
        this.id = null;
        this.localidadEntrega = null;
        this.entrega = 0;
        this.descuentoGold = 0;
        this.descuentoPremium = 0;
        this.items = null;
        this.email = null;
        this.celular = null;
        this.direccion = null;
        this.cuit = null;
        this.vendedorNombre = null;
        this.idVendedor = null;
        this.fechaCreacion = null;
        this.fechaUltimaActualizacion = null;
        this.iva = 0;
        this.localidad= null;
        this.provincia= null;
        this.codigoPostal= null;
        this.alicuotaIva = null;
        this.codigoDescuento = null;
        this.tipoCambioGastosExterior = 0;
        this.tipoCambioGastosArg = 0;
        this.res3244 = 0;
        this.gestionCompra = false;
        this.gestionEnvio = true;
        this.handlingARG = 0;
        this.handlingUSA = 0;
    }
}